<template>
  <div class="presentation-container">
    <div v-if="currentPresentationInfo.id && offlineMode" class="presentation-container__backBtn">
      <back-button @go-back="back" />
    </div>
    <presentation-slider v-if="currentPresentationInfo.id" />
    <presentation-list v-if="!currentPresentationInfo.id" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PresentationList from "@/components/common/presentation/PresentationList";
import { room, presentation } from "@/store/modules/store.namespaces";
import BackButton from "@/components/common/elements/BackButton";
import withGetPresentationInfo from "@/components/common/presentation/mixins/withGetPresentationInfo.mixin";
import PresentationSlider from "./components/presentation-slider/ScreenPresentationSlider.vue";

export default {
  name: "ScreenPresentation",
  components: {
    PresentationSlider,
    PresentationList,
    BackButton,
  },
  mixins: [withGetPresentationInfo],
  computed: {
    ...mapState(room, ["roomInfo"]),
    ...mapState(presentation, ["currentPresentationInfo"]),
    offlineMode() {
      return !this.roomInfo.Online_room;
    },
  },
};
</script>

<style scoped lang="less">
.presentation-container {
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  min-height: 300px;
  flex-direction: column;
}
.presentation-container__backBtn {
  padding: 10px 15px 5px 20px;
}
</style>
